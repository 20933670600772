export default {
    data() {
        return {
            search: {
                use_date_range: true,
                date_range: [],
            },
            picker_options: {
                'firstDayOfWeek': 1,
                shortcuts: [{
                    text: '当前时间',
                    onClick(picker) {
                        const date = new Date();
                        picker.$emit('pick', [date.Format('yyyy-MM-dd hh:mm'), date.Format('yyyy-MM-dd 23:30')]);
                    }
                },
                    {
                        text: '今日全天',
                        onClick(picker) {
                            const date = new Date();
                            picker.$emit('pick', [date.Format('yyyy-MM-dd 07:00'), date.Format('yyyy-MM-dd 23:30')]);
                        }
                    },
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start.Format('yyyy-MM-dd 07:00'), end.Format('yyyy-MM-dd 23:30')]);
                        }
                    },
                ]
            }
        }
    },
    created() {
        this.model = 'Admin.Lock';
        const date = new Date();
        if (this.$isMobile()) {
            this.search.date_range = [date.Format('yyyy-MM-dd'), date.Format('yyyy-MM-dd')];
        } else {
            this.search.date_range = [date.Format('yyyy-MM-dd hh:mm'), date.Format('yyyy-MM-dd 23:30')];
        }
        this.search.use_date_range = true;
        this.search.date = date.Format('yyyy-MM-dd');
    },
    computed: {
        show_favorite() {
            return this.search.filters[0] === 'f0';
        },
    },
    methods: {
        handleChange() {
            this.reload();
        },
        goSingleDateBack() {
            let first = this.$dateParse(this.search.date);
            first.setDate(first.getDate() - 1);
            this.search.date = first.Format('yyyy-MM-dd');
            this.search.date_range = [this.search.date, this.search.date];
            this.reload();
        },
        goSingleDateForward() {
            let first = this.$dateParse(this.search.date);
            first.setDate(first.getDate() + 1);
            this.search.date = first.Format('yyyy-MM-dd');
            this.search.date_range = [this.search.date, this.search.date];
            this.reload();
        },
        goDateChangeBack() {
            const valid = this.search.date_range && this.search.date_range.length === 2;
            let first = valid ? this.$dateParse(this.search.date_range[0].substr(0,10)) : new Date();
            let second = valid ? this.$dateParse(this.search.date_range[1].substr(0,10)) : new Date();

            first.setDate(first.getDate() - 1);
            second.setDate(second.getDate() - 1);
            this.search.date_range = [first.Format('yyyy-MM-dd 07:00'), second.Format('yyyy-MM-dd 23:30')];
            this.search.date = first.Format('yyyy-MM-dd');
            this.reload();
        },
        goDateChangeForward() {
            const valid = this.search.date_range && this.search.date_range.length === 2;
            let first = valid ? this.$dateParse(this.search.date_range[0].substr(0,10)) : new Date();
            let second = valid ? this.$dateParse(this.search.date_range[1].substr(0,10)) : new Date();

            first.setDate(first.getDate() + 1);
            second.setDate(second.getDate() + 1);
            this.search.date_range = [first.Format('yyyy-MM-dd 07:00'), second.Format('yyyy-MM-dd 23:30')];
            this.search.date = first.Format('yyyy-MM-dd');
            this.reload();
        },
    }
}
