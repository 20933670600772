import {mapState,mapGetters,mapMutations} from "vuex"
export default {
    computed:{
        ...mapState(['process','preset']),
        ...mapGetters('process',['isCollapsed','pageSize','which_semester']),
        ...mapGetters('user',['user']),
        en(){
            return this.$english();
        },
        english(){
            return this.$english();
        },
        is_mobile(){
          return this.$isMobile();
        },
    },
    methods:{
        ...mapMutations('user', ['clear',"setUserInfo"]),
        ...mapMutations('process', ['setCollapsed','setPageSize','setSemester']),
    }
}
