import CalendarLockDatePickerMixin from "@/views/mixins/calendar-lock-date-picker-mixin";
export default {
    name: 'meeting-lock',
    mixins:[CalendarLockDatePickerMixin],
    data() {
        return {
            search_user: '',
            single: {},
        }
    },
    created() {
        this.search.filters[0] = "f1";
        this.search.filters[1] = "c1";
    },
    methods: {
        lockMeeting() {
            this.$api('Admin.Schedule.Lock', {id: this.single.id, course: {id: 0}}, 1).then(() => {
                this.$notify.success('会议被强制结束和锁定!');
            });
        },
        openMeeting() {
            this.$api('Admin.Schedule.Unlock', {id: this.single.id, course: {id: 0}}, 1).then(() => {
                this.$notify.success('会议被开启!');
            });
        },

        coHost(user) {
            this.$api('Admin.Schedule.Cohost', {id: this.single.id, user: user}, true).then(() => {
                this.$notice.success(('设置完成!'));
            });
        },
        handleReview(id) {
            let vm = this;
            this.$api('Admin.Schedule.LiveParticipants', {id: id, review: true, in_meeting: true,}, true).then(res => {
                vm.single = res.data.data;
                this.view_mode = true;
            });
        },
    }
}
