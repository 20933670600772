<template>
    <section class="" v-loading = "process.loading">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     btn_review
                     @review="handleReview"
                     :btn_edit="false"
                     :btn_add="false"
                     :btn_delete="false"
                     :selection.sync='selection'
        >
            <div class="pull-right" style="margin-top:4px; ">
                <el-button size="small" icon="el-icon-arrow-left" :disabled="!search.use_date_range"
                           plain style="vertical-align: top; border-radius: 5px 0 0 5px;"
                           @click="goDateChangeBack" type="info"></el-button>
                <el-date-picker size="small" style="border-radius:0;border-left-width: 0;border-right-width: 0 "
                                @change="handleChange"
                                :disabled="!search.use_date_range"
                                v-model="search.date_range"
                                type="datetimerange"
                                :picker-options="picker_options"
                                range-separator="-"
                                start-placeholder="开始"
                                format="yyyy-MM-dd HH:mm"
                                :default-time="['07:00:00','23:30:00']"
                                value-format="yyyy-MM-dd HH:mm"
                                end-placeholder="结束">
                </el-date-picker>
                <el-button size="small" icon="el-icon-arrow-right" :disabled="!search.use_date_range"
                           plain style="vertical-align: top; border-radius: 0 5px 5px 0;"
                           @click="goDateChangeForward" type="info"></el-button>

            </div>
        </front-table>
        <!--编辑界面-->

        <el-dialog :title="single.title" :visible.sync="view_mode"
                   :close-on-click-modal="false" >

            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="d-flex">
                        <div class="page-label">会议id:</div>
                        <div class="page-content">{{single.meeting_code}} </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="d-flex">
                        <div class="page-label">会议时间:</div>
                        <div class="page-content">{{single.start?single.start.substr(0,16):''}} - {{single.end?single.end.substr(11,5):''}}
                        </div>
                    </div>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="d-flex">
                        <div class="page-label">参会链接:</div>
                        <div class="page-content">{{single.start_url}}</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="d-flex">
                        <div class="page-label">教师:</div>
                        <div class="page-content">{{single.teacher_id}} {{single.teacher_name}} </div>
                    </div>
                </el-col>

                <el-col :span="24" >
                    <el-table
                        class="bg-light"
                        size="mini"
                        :data="single.participants?single.participants.filter(data => !search_user || data.name.toLowerCase().includes(search_user.toLowerCase())):[]"
                        stripe
                        style="width: 100%">
                        <el-table-column prop="name" >
                            <div slot="header" class="d-flex" slot-scope="scope">
                                <el-input style="flex:1 1 5em; margin-right: 5px;" v-model="search_user" size="mini" v-if="scope" clearable
                                          placeholder="搜索用户"/>
                            </div>
                            <template slot-scope="scope">
                                <span :class="{'text-danger':(scope.row.user_role > 1)}">{{scope.row.name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="user_role" label="级别"></el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button v-if="scope.row.user_role < 2" @click="coHost(scope.row)" type="text" size="small" icon="el-icon-arrow-right">设为联席主持人</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
            <div slot="footer" class="dialog-footer">
                <div class="pull-left">
                <el-button type="danger" size="small" @click="lockMeeting" > 关闭会议 </el-button>
                    <el-button type="success" size="small" @click="openMeeting"> 开启会议 </el-button>
                </div>
                <el-button type="info" @click="view_mode=false">关 闭</el-button>
            </div>
        </el-dialog>


        <div class="clearfix"></div>
    </section>
</template>

<script>
    import FrontTable from "../components/front-table";
    import FrontMixins from "../common/mixins/front-mixins";
    import AdminLockMixins from "@/views/mixins/admin-lock-mixins";
    import commonMixin from "@/common/mixins/common-mixin";

    export default {
        mixins: [FrontMixins,AdminLockMixins,commonMixin],
        name: 'meeting-lock',
        components: {FrontTable},
    }
</script>

<style lang="scss">
.page-label {
    flex: 0 0 80px;
    width: 80px;
    text-align: left;
    font-weight: bold;
    display: inline-block;
    line-height: 30px;
    background-color: aliceblue;
    padding: 0 5px;
}

.page-content {
    flex: 1;
    text-align: left;
    margin-left: 10px;
    display: inline-block;
    line-height: 30px;
    border-bottom: solid 1px #b2ebf2;
    overflow: auto;
}

</style>
